<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg-career.jpeg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">{{ $t("hiring") }}</h1>
        </div>
      </div>
    </div>
    
    <div class="section">
      <div class="container">
        <div class="card mb-3 text-center" v-for="jobPost in orderedJobPosts" :key="jobPost.id">
          <div class="card-body">
            <h4 class="card-title">{{ jobPost[$i18n.locale].title }}</h4>
            <p class="card-text">{{ jobPost[$i18n.locale].overview }}</p>
            <button type="button" class="btn btn-lg" @click="showDetails(jobPost.id)">{{ $t("job_details") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const _ = require('lodash');

export default {
  name: 'career',
  bodyClass: 'landing-page',
  computed: {
    orderedJobPosts() {
      return _.orderBy(this.jobPosts, ['id'], ['desc']);
    }
  },
  data() {
    return {
      jobPosts: []
    }
  },
  methods: {
    async fetchJobPosts() {
      const res = await axios.post('/jobPost/content');
      this.jobPosts = res.data;
    },
    showDetails(jobPostId) {
        this.$router.push(`/career/${jobPostId}`);
    },
  },
  async created() {
    await this.fetchJobPosts();
  }
};
</script>
<style></style>
