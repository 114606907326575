<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg-career.jpeg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title">{{ $t("hiring") }}</h1>
        </div>
      </div>
    </div>
    
    <div class="section">
      <div class="container">
        <h3>{{ title }}</h3>
        <p>{{ overview }}</p>
        <h3>{{ $t("job_description") }}</h3>
        <p v-for="desc in job_descriptions" :key="desc">・{{ desc }}</p>
        <h3>{{ $t("job_requirements") }}</h3>
        <p v-for="req in job_requirements" :key="req">・{{ req }}</p>
        <h3>{{ $t("job_advanced_requirements") }}</h3>
        <p v-for="adv_req in job_advanced_requirements" :key="adv_req">・{{ adv_req }}</p>
        <h3>{{ $t("job_benefits") }}</h3>
        <p v-for="benefit in benefits" :key="benefit">・{{ benefit }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const _ = require('lodash'); 

export default {
  name: 'job_details',
  bodyClass: 'landing-page',
  computed: {
    title() {
      return this.jobPost[this.$i18n.locale.toUpperCase()]?.title || this.jobPost[this.$i18n.locale.toUpperCase()]?.title_trans
    },
    overview() {
      return this.jobPost[this.$i18n.locale.toUpperCase()]?.overview || this.jobPost[this.$i18n.locale.toUpperCase()]?.overview_trans
    },
    job_descriptions() {
      const description = this.jobPost[this.$i18n.locale.toUpperCase()]?.description || this.jobPost[this.$i18n.locale.toUpperCase()]?.description_trans
      return _.split(description, /\s*;\s*/)
    },
    job_requirements() {
      const requirements = this.jobPost[this.$i18n.locale.toUpperCase()]?.primary_requirements || this.jobPost[this.$i18n.locale.toUpperCase()]?.primary_requirements_trans
      return _.split(requirements, /\s*;\s*/)
    },
    job_advanced_requirements() {
      const advanced_requirements = this.jobPost[this.$i18n.locale.toUpperCase()]?.additional_requirements || this.jobPost[this.$i18n.locale.toUpperCase()]?.additional_requirements_trans
      return _.split(advanced_requirements, /\s*;\s*/)
    },
    benefits() {
      const benefits = this.jobPost[this.$i18n.locale.toUpperCase()]?.benefits || this.jobPost[this.$i18n.locale.toUpperCase()]?.benefits_trans
      return _.split(benefits, /\s*;\s*/)
    }
  },
  data() {
    return {
      jobPost: {},
    }
  },
  methods: {
    async fetchJobWithTranslationsById() {
      const res = await axios.post(`/jobPost/content/${this.$route.params.id}`);
      this.jobPost = res.data;
    },
  },
  async created() {
    await this.fetchJobWithTranslationsById();
  }
};
</script>
<style></style>
